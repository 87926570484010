import request from '../utils/request.js'

//创建订单
export function addorder(data) {
	return request({
		url: `/order/createOrder`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//支付宝支付
export function orderzfb(sn) {
	return request({
		url: `/order/${sn}/getAliPayQrCode`,
		method: 'get'
	})
}
//微信支付
export function orderwx(sn) {
	return request({
		url: `/order/${sn}/getWxPayQrCode`,
		method: 'get'
	})
}
//判断微信支付是否成功
export function orderwxpd(sn) {
	return request({
		url: `/order/${sn}/wxPayStatus`,
		method: 'get'
	})
}
//订单详情
export function orderdet(sn) {
	return request({
		url: `/order/${sn}`,
		method: 'get'
	})
}
//获取订单列表
export function getorderlist(params) {
	return request({
		url: `/order/list`,
		method: 'get',
		params
	})
}
//取消订单
export function delelist(sn) {
	return request({
		url: `/order/cancel/${sn}`,
		method: 'get'
	})
}
//获取总账单列表
export function billlist(params) {
	return request({
		url: `/bill/list`,
		method: 'get',
		params
	})
}
//获取详细账单列表
export function detalist(params) {
	return request({
		url: `/bill/detail`,
		method: 'get',
		params
	})
}
//获取流量账单明细列表
export function flowlist(params) {
	return request({
		url: `/bill/itemdetail`,
		method: 'get',
		params
	})
}
//获取流量账单明细列表
export function storagelist(params) {
	return request({
		url: `/bill/itemdetailStorage`,
		method: 'get',
		params
	})
}